import React from "react";

export default function Combo() {
  return (
    <div className="combo">
      <div className="auto__container">
        <div className="combo__inner">
          <div className="combo__inner-content">
            <h2>FATA AF NÖGGUM</h2>
            <ul>{/* <li>1</li> */}</ul>
            <ul>
              <li>6 stk: 1.479 kr.</li>
              <li>9 stk: 1.899 kr.</li>
              <li>25 stk: 4.999kr kr.</li>
            </ul>
          </div>
          <div className="combo__inner-image">
            <img
              src={process.env.PUBLIC_URL + "/images/fotur/naggafata.png"}
              alt="combo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
