import React, { useEffect, useState } from "react";
import Barna from "./Sections/Barna";
import Combo from "./Sections/Combo";
import Delivery from "./Sections/Delivery";
// import Intro from "./Sections/Intro";
import Menu from "./Sections/Menu";
// import BBQ from "./Sections/BBQ/BBQ";
import Fam from "./Sections/Fam/Fam";
import Cafe from "./Sections/Cafe/Cafe";
import Side from "./Sections/Side/Side";
import Stakt from "./Sections/Stakt/Stakt";
import Start from "./Sections/Start/Start";
import Desert from "./Sections/Desert/Desert";
import Car from "./Sections/Car";
import Hotwings from "./Sections/HotWings";
import { sanityClient } from "../../config/client";
import { themeOptions } from "../../config/queries";
import Combo2 from "./Sections/Combo2";

export default function Home({ setMenu, menu }) {
  const [products, setProducts] = useState();

  useEffect(() => {
    async function getPosts() {
      const homePage = await sanityClient.fetch(themeOptions);
      console.log("🚀 ~ getPosts ~ homePage:", homePage);
      setProducts(homePage);
    }
    getPosts();
  }, []);

  return (
    <>
      <div className="wrapper">
        {/* <Intro data={products?.slider_products} /> */}
        <div className="video-container">
          <video
            muted={true}
            autoPlay={true}
            loop={true}
            src={process.env.PUBLIC_URL + "/videos/metro-min-mute.mp4"}
            style={{ width: "100%" }}
          />
        </div>
        <Menu menu={menu} setMenu={setMenu} />
      </div>

      <div id="STJÖRNUMÁLTÍÐ" className="anchor top"></div>
      <Start data={products?.star_meal} />
      <div id="STAKT & GOTT" className="anchor"></div>
      <Stakt data={products?.single_and_good_products} />
      <div id="BARNABOX" className="anchor"></div>
      <Barna data={products?.barnabox} />
      <div id="KJÚKLINGUR" className="anchor"></div>
      {/* <BBQ /> */}
      <Combo />
      <Hotwings />
      <Combo2 />
      <div id="HEIMSENDING" className="anchor"></div>
      <Delivery />
      <div id="MEÐLÆTI" className="anchor"></div>
      <Side data={products?.side_dish_products} />
      <div id="FJÖLSKYLDUTILBOÐ" className="anchor"></div>
      <Fam data={products?.family_offer} />
      <div id="METRO CAFÉ" className="anchor"></div>
      <Cafe />
      <div id="EFTIRRÉTTIR" className="anchor"></div>
      <Desert data={products?.desserts_products} />
      <Car />
    </>
  );
}
