import React from "react";

export default function Combo2() {
  return (
    <div className="combo">
      <div className="auto__container">
        <div className="combo__inner">
          <div className="combo__inner-content">
            <h2>Kjúklinga combo</h2>
            <ul>{/* <li>1</li> */}</ul>
            <p>
              <b>4</b> Lundir, <b>6</b> Naggar, <b>18</b> Pop kjúklingar + 3
              sósur
            </p>
            <b>3.199 kr.</b>
          </div>
          <div className="combo__inner-image">
            <img
              src={process.env.PUBLIC_URL + "/images/fotur/kjuklingacombo.png"}
              alt="combo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
